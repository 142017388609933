<template>
  <div class="page-wrap">
    <Helper></Helper>
    <div class="section-wrap section-search">
      <InnerSearch
        :area="optionsArea"
        :industry="optionsIndustry"
        :keyword="pagination.searchStr"
        @search="handleSearch"
      ></InnerSearch>
      <div class="wrapper-left">
        <breadcrumbs></breadcrumbs>
        <div class="wrapper-content">
          <div class="wrapper-list" :loading="loading">
            <ItemWXGroup
              class="item"
              v-for="(item, index) in pageData.list"
              :data="item"
              :key="item.id"
            ></ItemWXGroup>
            <el-empty description="无结果" v-show="empty" />
          </div>
          <div class="wrapper-right">
            <QuickPublishProject path="resource" buttonLabel="发布资源"></QuickPublishProject>
            <BlockSuggestWXGroup class="box-suggest"></BlockSuggestWXGroup>
          </div>
        </div>
      </div>

      <div class="wrapper-page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageData.total"
          :page-size="12"
          @current-change="handlePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent, computed, watch } from 'vue'
import qs from 'qs'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Options, Vue } from 'vue-class-component'
import ItemWXGroup from '@/components/ItemWXGroup.vue'
import InnerSearch from '@/components/InnerSearch.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestWXGroup from '@/components/BlockSuggestWXGroup.vue'
import Helper from '@/components/Helper.vue'
import { chinaProvince } from '@/libs/defined'
import { Api } from '@/services/http'

const route = useRoute()
const optionsArea = reactive([...chinaProvince])
const optionsIndustry = computed(()=>{
  let items = [];
  if( pageData.businessItems.length ){
    items = pageData.businessItems
  }
  if( store.state.base.businessItems.length ){
    items = store.state.base.businessItems
  }

  const find = items.find(item=> item.type =='industry')
  if( find ){
    return find.items;
  }else{
    return []
  }
})

const loading = ref(false)
const pageData = reactive({
  businessItems:[],
  list:[],
  state:-1
})
const store = useStore()
// const optionsIndustry =
const industry  = computed(() => {
  return store.state.base.businessItems;
})
const empty = computed(()=>{
  return pageData.state !=-1 && pageData.list.length<=0
})
watch(industry, newData => {
  pageData.businessItems = [...newData];
})

const pagination = reactive({
  current: 1,
  size: 12,
  searchStr: '',
  city: '',
  industry: undefined,
})
if( route.query.keyword ){
  pagination.searchStr = route.query.keyword
}
console.log('params', route)


const mounted = async () => {
  console.log('Api', Api)
  pageData.state = 1;
  loadPage()
}
const handleSearch = (key, area, industry) => {
  pagination.searchStr = key
  if (area) {
    pagination.city = area
  } else {
    pagination.city = undefined
  }
  if (industry) {
    pagination.industry = industry
  } else {
    pagination.industry = undefined
  }
  loadPage()
}
const handlePage = page => {
  loadPage(page)
}
const loadPage = async (page = 1) => {
  loading.value = true
  pagination.current = page
  let data = await Api.post(
    '/client-api/resourceGroup/list',
    qs.stringify(pagination)
  )
  console.log('list', data)
  if (data) {
    let list = (data.list || []).filter(item => item.auditStatus == 2)
    pageData.list = [...list]
    pageData.total = data.total
  }
  loading.value = false
}
mounted()


console.log('子页面加载')
</script>
<style lang="scss" scoped>
.section-search {
  padding-bottom: 30px;

  .wrapper-left {
    padding-top: 30px;
  }
  .wrapper-content {
    display: flex;
    .wrapper-right {
      flex-basis: 260px;
      margin-left: 30px;
    }
    .box-suggest {
      margin-top: 20px;
    }
  }
  .wrapper-list {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    .item {
      width: 33.3%;
      float: left;
      box-sizing: border-box;
      margin-bottom: 20px;
      padding-bottom: 20px;
      text-align: left;
      &::after {
        content: '';
        zoom: 1;
        clear: both;
      }
    }
  }
  .wrapper-page {
    padding: 20px;
  }
}
</style>
